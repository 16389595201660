import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-template-strings",
  "date": "2015-09-12",
  "title": "ES6 TEMPLATE STRINGS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Template strings in JavaScript consist of literal text, and placeholders where the runtime can evaluate an expression to inject data into the text."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why template strings?`}</h2>
    <p>{`Before we delve into template strings, let's identify the capabilities of some languages like `}<inlineCode parentName="p">{`Ruby`}</inlineCode>{` and `}<inlineCode parentName="p">{`Python`}</inlineCode>{` that are lacking in JavaScript.`}</p>
    <ul>
      <li parentName="ul">{`String formatting`}</li>
      <li parentName="ul">{`String tagging for safe HTML escaping & localization`}</li>
      <li parentName="ul">{`Multiple strings without hacks`}</li>
      <li parentName="ul">{`Embedded expressions`}</li>
      <li parentName="ul">{`String interpolation`}</li>
    </ul>
    <p>{`Template strings work more like a function call than a mechanism for defining templates.`}</p>
    <h2>{`Syntax`}</h2>
    <p>{`Template strings use back-ticks (`}<strong parentName="p">{`\`\``}</strong>{`) rather than the single or double quotes we use for regular strings. A template string could thus be written as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var message = \`Hello!\`;
`}</code></pre>
    <Message type="info" title="" content="Backtick characters **(`)** enclose the entire template literal, while **$** and **{}** denote the substitutions. Inside a substitution, you can use identifiers and expressions." mdxType="Message" />
    <h2>{`String Substitution`}</h2>
    <p>{`String substitution allows us to take any valid JavaScript expression and inside a `}<inlineCode parentName="p">{`Template Literal`}</inlineCode>{`, the result will be output as part of the same string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var name = 'Sean';
var message = \`Hello \${name}!\`;

console.log(message); // Hello Sean!
`}</code></pre>
    <p>{`You are not limited to variables, you can use functions inside an expression:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var dateOfBirth = 1985;
var message = \`My age is \${2015 - dateOfBirth}\`;

console.log(message); // My age is 30
`}</code></pre>
    <h2>{`Multiline Strings`}</h2>
    <p>{`Multiline strings in JavaScript has been achieved so far by adding a backslash (**`}{`*`}{`*) character before each newline.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var message = "This is a \\
              multi-line message";
`}</code></pre>
    <p>{`Template strings can span multiple lines:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var message = \`This is a
              multi-line message\`;
`}</code></pre>
    <Message type="info" title="" content="Multiline literals will contain a newline character in template strings." mdxType="Message" />
    <h2>{`Tagged Templates`}</h2>
    <p>{`Tagged Templates transform a Template String by placing a function name before the template string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var name = 'Sean';
var dateOfBirth = 1985;

fn\`Hello \${name}! You are \${2015 - dateOfBirth} today\`;
`}</code></pre>
    <p>{`Tagged template strings are a special type of a function call. The above string will be converted into:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var name = 'Sean';
var dateOfBirth = 1985;

fn(["Hello ", "! You are ", " today"], name, dateOfBirth);
`}</code></pre>
    <h2>{`Safe HTML escaping`}</h2>
    <p>{`You could write a HTML-escaping function as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`html\`<p title="\${title}">Hello \${name}</p>!\`
`}</code></pre>
    <p>{`This returns a string with the appropriate variables substituted in, but with all HTML-unsafe characters replaced.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      